import React from 'react';

import { SIGNUP_URL } from 'library/constants.js';

import style from './style.module.scss';

import IndyLink from 'components/IndyLink';

class CallsToAction extends React.Component {
    constructor(props) {
        super(props);
    
        if (!props.types){
            throw new Error('Required props missing');
        }
    }

    render() { 
        var buttonDivs = [];
        for (var ind = 0; ind < this.props.types.length; ind++) {
            var button;

            let buttonStyle = style.button;
            switch (this.props.types[ind]) {
                case "SignUp":
                    buttonStyle += " " + style.signup;
                    break;

                case "LearnMore":
                case "AddOns":
                case "Demo":
                case "ContactUs":
                    buttonStyle += " " + style.learnMore;
                    break;

                default:
                    throw new Error('Unknown call to action type');
            }

            switch (this.props.types[ind]) {
                case "SignUp":
                    button =    <IndyLink className={style.CTALink} key={this.props.types[ind]} adr={SIGNUP_URL}>
                                    <div className={buttonStyle}>
                                        <label>Sign up</label>
                                    </div>
                                </IndyLink>;
                    break;

                case "ContactUs":
                    button =    <IndyLink className={style.CTALink} key={this.props.types[ind]} adr={"/about/#ContactUs"}>
                                    <div className={buttonStyle}>
                                        <label>Get in contact</label>
                                    </div>
                                </IndyLink>;
                    break;

                case "LearnMore":
                    button =    <IndyLink className={style.CTALink} key={this.props.types[ind]} adr="/modules">
                                    <div className={buttonStyle}>
                                        <label>Our modules</label>
                                    </div>
                                </IndyLink>;
                    break;

                case "AddOns":
                    button =    <IndyLink className={style.CTALink} key={this.props.types[ind]} adr="/addons">
                                    <div className={buttonStyle}>
                                        <label>See add-ons</label>
                                    </div>
                                </IndyLink>;
                    break;

                case "Demo":
                    button =    <IndyLink className={style.CTALink} key={this.props.types[ind]} adr="/about/#ContactUs">
                                    <div className={buttonStyle}>
                                        <label>Request a demo</label>
                                    </div>
                                </IndyLink>;
                    break;
            
                default:
                    throw new Error('Unknown call to action type');
            }

            buttonDivs.push(button);
        }

        let className = style.container;
        if (this.props.contClassName){
            className = className + " " + this.props.contClassName;
        }

        return (
            <div className={className}>
                {buttonDivs}
            </div>
        );
    }
}

export default CallsToAction;