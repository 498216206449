import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import FormsIcon from 'media/shared/modIcons/Forms.inline.svg';

import fieldSetupImg from 'media/Screenshots/Forms/FormFieldSetup.png';
import templateEditorImg from 'media/Screenshots/Forms/FormTemplateEditor.png';
import formFillingImg from 'media/Screenshots/Forms/FormFilling.png';
import formSearchImg from 'media/Screenshots/Forms/FormSearch.png';

export default function ModForms({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/forms";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Forms Module - Mashoom"
                description="Create a form to collect data and / or generate documents."
                previewImgKey="forms"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={FormsIcon}
                imageAlt="Forms module icon"
            >
                <h1>The Forms Module</h1>
                <p>Form filling is an age old process that pre-dates the tech revolution. Our module provide a way to modernize this process whilst allowing backwards compatibility with existing systems.</p>
            </PagePicTitle>
            <FeaturePoint
                title="You decide the data you want to collect"
                image={fieldSetupImg}
                pageInd="1"
            >
                <p>Define the fields if your form, there are many field types to choose from and specific validations can be set for each. This also allows Mashoom to give the form filler the most helpful input and validate as they go along.</p>
                <p className="paraSpaceTop">It can be as simple as that, but what if you have variants of forms where only a few fields are different? Mashoom allows a hierarchical structure of form fields to be built, allowing variants of forms. The result is that as your forms evolve, Mashoom remains quick and easy to update, rather than having to update each variant individually.</p>
                <p className="paraSpaceTop">Photos / generic files can also be attached to forms, as well as calculated fields for basic calculations and logic; we designed this to deal with some tricky use cases.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Based around a PDF template"
                image={templateEditorImg}
                pageInd="2"
            >
                <p>A PDF template is uploaded to form the basis of the form. This could either be a template that is currently being used in an existing workflow, or a new one for a new process.</p>
                <p className="paraSpaceTop">Our simple UI allows you to select where you want your fields to go, along with various styling options so you can get your forms looking great. A photo gallery or text appendix can also be setup, allowing forms with a lot of information to be handled clearly.</p>
                <p className="paraSpaceTop">Date stamping and signing with anti-forgery technology allows these forms to be used as part of a controlled process, using the platform's advanced security to underpin when a form was completed and by whom.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Simple, quick and efficient data collection"
                image={formFillingImg}
                pageInd="3"
            >
                <p>A simple interface allows the form to be filled in, split into sections and ordered as you setup. The form can be filled in online via any device, or download a template in spreadsheet format for offline filling.</p>
                <p className="paraSpaceTop">Each input is validated as it's filled in, allowing data validation as you go. Whether a field is required or not is optional, and fields not printed on the template can be collected.</p>
                <p className="paraSpaceTop">Skipping conditions can be setup, for instance allowing a section to be skipped if the previous values mean it's not necessary.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Zero effort filing, powerful searching and reporting you control"
                image={formSearchImg}
                pageInd="4"
            >
                <p>When a form is completed, a static PDF is generated that can be downloaded at any time. The data is stored in our bespoke database engine for lookup and analysis.</p>
                <p className="paraSpaceTop">The result is that any field collected by the form can be searched for, allowing simpler and faster retrieval than any paper based system and likely most automated systems.</p>
                <p className="paraSpaceTop">Data analytics can then be performed on the data collected. Reports can be built that delve into any of the fields you setup, for instance showing the number of each field value over time. Of course, all your data can simple be downloaded in spreadsheet format for your own analysis or integration.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}